import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  is_sticky_communication: false,
  is_large_popout: false,
  is_main_communication: false,
  current_draft: '',
  current_view: '', // 'maximized' or 'minimized'
};

const slice = createSlice({
  name: 'stickyCommunications',
  initialState,
  reducers: {
    setStickyCommunication: (stickyCommunications, action) => {
      stickyCommunications.is_sticky_communication = action.payload;
    },
    setMainCommunication: (stickyCommunications, action) => {
      stickyCommunications.is_main_communication = action.payload;
    },
    setCurrentCommunicationDraft: (stickyCommunications, action) => {
      stickyCommunications.current_draft = action.payload;
    },
    setLargePopout: (stickyCommunications, action) => {
      const { is_large_popout, cb } = action.payload;
      stickyCommunications.is_large_popout = is_large_popout;
      if (cb) cb();
    },
    setStickyCommunicationView: (stickyCommunications, action) => {
      stickyCommunications.current_view = action.payload;
    },
  },
});



export const {
  setStickyCommunication,
  setMainCommunication,
  setCurrentCommunicationDraft,
  setLargePopout,
  setStickyCommunicationView,
} = slice.actions;



export default slice;
